/**
 * @name Header
 * @description Class to apply functionality to the Header
 */
class Header {
  constructor() {
    /**
     * @name header
     * @type {HTMLElement}
     */
    this.header = document.getElementById('header');

    /**
     * @name navToggle
     * @type {HTMLElement}
     */
    this.navToggle = document.getElementById('header__nav-toggle');

    /**
     * @name navChildToggles
     * @type {HTMLElement}
     */
    this.navChildToggles = [...document.getElementsByClassName('header__nav-list-children-toggle')];

    this.init();
  }

  /**
   * @method init
   * @memberof Header
   */
  init() {
    this.bindEvents();
  }

  /**
   * Binds the Header events
   *
   * @method bindEvents
   * @memberof Header
   */
  bindEvents() {
    this.navToggle.addEventListener('click', () => { this.toggleNav(); });
    this.navChildToggles.forEach((navChildToggle) => {
      navChildToggle.addEventListener('click', (e) => { this.toggleNavChild(e); });
    });
  }

  /**
   * Toggles the Nav open/closed
   *
   * @method toggleNav
   * @memberof Header
   */
  toggleNav() {
    if (this.navIsToggled) {
      document.body.classList.remove('header__nav--toggled');
      this.navIsToggled = false;
    } else {
      document.body.classList.add('header__nav--toggled');
      this.navIsToggled = true;
    }
  }

  /**
   * Toggles the Nav Children open/closed
   *
   * @method toggleNavChild
   * @memberof Header
   */
  toggleNavChild(e) {
    e.target.parentNode.classList.toggle('header__nav-list-children-toggle--toggled');
  }
}

export default Header;
