/**
 * @name Quote
 * @description Class to apply functionality to the Quote
 */
class Quote {
  constructor() {
    /**
     * @name quoteToggles
     * @type {HTMLElement}
     */
    this.quoteToggles = [...document.getElementsByClassName('quote__toggle')];

    this.init();
  }

  /**
   * @method init
   * @memberof Quote
   */
  init() {
    this.bindEvents();
  }

  /**
   * Binds the Quote events
   *
   * @method bindEvents
   * @memberof Quote
   */
  bindEvents() {
    this.quoteToggles.forEach((quoteToggle) => {
      quoteToggle.addEventListener('click', (e) => { this.toggleQuote(e); });
    });
  }

  /**
   * Toggles the Quote open/closed
   *
   * @method toggleQuote
   * @memberof Quote
   */
  toggleQuote(e) {
    e.target.parentNode.parentNode.parentNode.classList.toggle('quote__toggle--open');
  }
}

export default Quote;
