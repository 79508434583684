import axios from 'axios';
import Pristine from 'pristinejs';
import formConfig from '../../../../config/form';

/**
 * @name ContactForm
 * @description Class to apply functionality to the ContactForm
 */
class ContactForm {
  constructor() {
    /**
     * @name form
     * @type {HTMLElement}
     */
    this.contactForm = document.getElementById('contact-form');

    /**
     * @name form
     * @type {HTMLElement}
     */
    this.form = document.getElementById('contact-form__form');

    this.formSubmit = this.formSubmit.bind(this);
    this.init();
  }

  /**
   * @method init
   * @memberof ContactForm
   */
  init() {
    this.setupForm();
    this.bindEvents();
  }

  /**
   * Setup Form Validation
   *
   * @method setupForm
   * @memberof ContactForm
   */
  setupForm() {
    this.pristine = new Pristine(this.form, formConfig);
  }

  /**
   * Binds the ContactForm events
   *
   * @method bindEvents
   * @memberof ContactForm
   */
  bindEvents() {
    this.form.addEventListener('submit', (e) => { this.formSubmit(e); });
  }

  /**
   * Submit the form
   *
   * @method formSubmit
   * @memberof ContactForm
   */
  formSubmit(e) {
    e.preventDefault();
    if (this.pristine.validate()) {
      this.contactForm.classList.add('form--loading');

      // eslint-disable-next-line no-undef
      const formData = new FormData(this.form);
      const params = new URLSearchParams();
      params.append('firstName', formData.get('contact_first_name'));
      params.append('lastName', formData.get('contact_last_name'));
      params.append('email', formData.get('contact_email'));
      params.append('message', formData.get('contact_message'));
      params.append('number', formData.get('contact_number'));
      axios.post('/wp-json/destinyarts/v1/sendmail', params).then((res) => {
        if (res.data === 'success') {
          this.contactForm.classList.remove('form--loading');
          this.contactForm.classList.add('form--success');
        } else {
          this.contactForm.classList.remove('form--loading');
          this.contactForm.classList.add('form--error');
        }
      }).catch(() => {
        this.contactForm.classList.remove('form__loading');
        this.contactForm.classList.add('form--error');
      });
    }
  }
}

export default ContactForm;
