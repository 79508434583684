/* eslint-disable class-methods-use-this */
import A11yDialog from 'a11y-dialog';

/**
 * @name DialogVideo
 * @description Class to apply functionality to the Header
 */
class DialogVideo {
  constructor() {
    /**
     * @type {HTMLElement}
     */
    this.videoDialogIFrame = document.getElementById('video-frame');

    /**
     * @type {Array<[HTMLElement]>}
     */
    this.videoDialogBtns = [...document.getElementsByClassName('js-video-dialog-btn')];

    /**
     * @type {HTMLElement}
     */
    const dialogEl = document.getElementById('video-dialog');
    const mainEl = document.getElementById('main');
    this.videoDialog = new A11yDialog(dialogEl, mainEl);

    this.bindEvents();
  }

  /**
   * Bind Events
   *
   * @method bindEvents
   * @memberof DialogVideo
   */
  bindEvents() {
    this.videoDialogBtns.forEach((videoDialogBtn) => {
      const videoUrl = videoDialogBtn.getAttribute('data-video-url');
      videoDialogBtn.addEventListener('click', () => {
        this.setVideoUrl(videoUrl);
      });
    });

    // Opening video dialog
    this.videoDialog.on('show', () => {

    });

    // Closing video dialog
    this.videoDialog.on('hide', () => {
      this.setVideoUrl('');
    });

    // Fix to hide dialog when clicked outside
    document.addEventListener('click', (e) => {
      if (e.target.tagName === 'DIALOG') {
        this.videoDialog.hide();
      }
    });
  }

  /**
   * Set the videoUrl in iFrame src
   *
   * @method setVideoUrl
   * @param {Number} currentIndex
   * @memberof PageIssues
   */
  setVideoUrl(videoUrl) {
    this.videoDialogIFrame.setAttribute('src', videoUrl);
  }
}

export default DialogVideo;
