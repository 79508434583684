/**
 * Set the video type
 *
 * @method supportsVideoType
 * @param {String} type
 */
const supportsVideoType = (type) => {
  let videoChecker;

  const formats = {
    ogg: 'video/ogg; codecs="theora"',
    h264: 'video/mp4; codecs="avc1.42E01E"',
    webm: 'video/webm; codecs="vp8, vorbis"',
    vp9: 'video/webm; codecs="vp9"',
    hls: 'application/x-mpegURL; codecs="avc1.42E01E"',
  };

  if (!videoChecker) {
    videoChecker = document.createElement('video');
  }

  return videoChecker.canPlayType(formats[type] || type);
};

export default supportsVideoType;
