/**
 * @name Progress
 * @description Class to apply functionality to the Progress
 */
class Progress {
  constructor() {
    /**
     * @name progressBar
     * @type {HTMLElement}
     */
    this.progressBar = document.getElementById('progress__bar');
    this.init();
  }

  /**
   * @method init
   * @memberof Progress
   */
  init() {
    this.bindEvents();
  }

  /**
   * Binds the Progress events
   *
   * @method bindEvents
   * @memberof Progress
   */
  bindEvents() {
    window.addEventListener('scroll', () => {
      this.onScroll();
    });
  }

  /**
   * Adjust the width based on the scroll position
   *
   * @method onScroll
   * @memberof Header
   */
  onScroll() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    this.progressBar.style.width = `${scrolled}%`;
  }
}

export default Progress;
