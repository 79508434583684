/* eslint-disable class-methods-use-this */
import supportsVideoType from '../../../utils/supportsVideoType';

/**
 * @name PageHomeHero
 * @description Class to apply functionality to the intro component
 */
class PageHomeHero {
  constructor() {
    /**
     * @name videoEl
     * @type {HTMLElement}}
     */
    this.videoEl = document.getElementById('page-home-hero__video');

    this.setVideoUrl();
  }

  /**
   * Set the videoUrl
   *
   * @method setVideoUrl
   * @memberof PageHomeHero
   */
  setVideoUrl() {
    const path = this.videoEl.getAttribute('data-video-url');

    // Set the video size
    let videoSize = '320';
    if (window.innerWidth >= 321) {
      videoSize = '730';
    }
    if (window.innerWidth >= 731) {
      videoSize = '1400';
    }

    // Check which video type it is
    if (supportsVideoType('webm')) {
      this.videoEl.src = `${path}_${videoSize}.webm`;
      this.videoEl.type = 'video/webm';
    } else if (supportsVideoType('h264')) {
      this.videoEl.src = `${path}_${videoSize}.mp4`;
      this.videoEl.type = 'video/mp4';
    }
  }
}

export default PageHomeHero;
