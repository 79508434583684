/* eslint-disable class-methods-use-this */
import A11yDialog from 'a11y-dialog';

/**
 * @name DialogStaff
 * @description Class to apply functionality to the Header
 */
class DialogStaff {
  constructor() {
    /**
     * @type {Array<[HTMLElement]>}
     */
    this.staffDialogBtns = [...document.getElementsByClassName('js-staff-dialog-btn')];

    /**
     * @type {HTMLElement}
     */
    this.dialogImg = document.getElementById('dialog-staff__img-wrap');

    /**
     * @type {HTMLElement}
     */
    this.dialogTitle = document.getElementById('dialog-staff__title');

    /**
     * @type {HTMLElement}
     */
    this.dialogRole = document.getElementById('dialog-staff__role');

    /**
     * @type {HTMLElement}
     */
    this.dialogBio = document.getElementById('dialog-staff__bio');

    /**
     * @type {HTMLElement}
     */
    const dialogEl = document.getElementById('staff-dialog');

    /**
     * @type {HTMLElement}
     */
    const mainEl = document.getElementById('main');
    this.staffDialog = new A11yDialog(dialogEl, mainEl);

    this.bindEvents();
  }

  /**
   * Bind Events
   *
   * @method bindEvents
   * @memberof DialogStaff
   */
  bindEvents() {
    this.staffDialogBtns.forEach((staffDialogBtn) => {
      staffDialogBtn.addEventListener('click', (e) => {
        e.preventDefault();
        const staffImg = [...e.target.getElementsByClassName('staff__img')][0];
        const staffTitle = [...e.target.getElementsByClassName('staff__text-title')][0];
        const staffRole = [...e.target.getElementsByClassName('staff__text-role')][0];
        const staffBio = [...e.target.getElementsByClassName('staff__text-bio')][0];
        const clonedImg = staffImg.cloneNode(true);
        this.dialogImg.innerHTML = '';
        this.dialogImg.appendChild(clonedImg);

        this.dialogTitle.innerHTML = staffTitle.innerHTML;
        this.dialogRole.innerHTML = staffRole.innerHTML;
        this.dialogBio.innerHTML = staffBio.innerHTML;
      });
    });

    // Closing video dialog
    this.staffDialog.on('hide', () => {
      this.dialogImg.innerHTML = '';
      this.dialogTitle.innerHTML = '';
      this.dialogRole.innerHTML = '';
      this.dialogBio.innerHTML = '';
    });

    // Fix to hide dialog when clicked outside
    document.addEventListener('click', (e) => {
      if (e.target.tagName === 'DIALOG') {
        this.staffDialog.hide();
      }
    });
  }
}

export default DialogStaff;
