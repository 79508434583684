import Pristine from 'pristinejs';
import formConfig from '../../../config/form';

/**
 * @name NewsletterForm
 * @description Class to apply functionality to the NewsletterForm
 */
class NewsletterForm {
  constructor() {
    /**
     * @name form
     * @type {HTMLElement}
     */
    this.form = document.getElementById('newsletter-form');

    this.init();
  }

  /**
   * @method init
   * @memberof NewsletterForm
   */
  init() {
    this.setupForm();
    this.bindEvents();
  }

  /**
   * Setup Form Validation
   *
   * @method setupForm
   * @memberof NewsletterForm
   */
  setupForm() {
    this.pristine = new Pristine(this.form, formConfig);
  }

  /**
   * Binds the NewsletterForm events
   *
   * @method bindEvents
   * @memberof NewsletterForm
   */
  bindEvents() {
    this.form.addEventListener('submit', (e) => {
      if (!this.pristine.validate()) {
        e.preventDefault();
      }
    });
  }
}

export default NewsletterForm;
