import Rellax from 'rellax';
import Header from './components/header/Header';
import ScrollAnimate from './utils/scrollAnimate';
import DialogVideo from './components/dialog/dialog-video';
import DialogStaff from './components/dialog/dialog-staff';
import Quote from './components/flexible-content/quote/quote';
import Share from './components/share/share';
import Progress from './components/progress/progress';
import NewsletterForm from './components/newsletter-form/newsletter-form';
import ContactForm from './components/flexible-content/contact-form/contact-form';
import PageHomeHero from './pages/page-home/page-home-hero/page-home-hero';

// Add Parallax
new Rellax('.rellax');

// Add Scroll Animation
new ScrollAnimate();

// Add Component and Page Scripts
new Header();

if (document.getElementsByClassName('dialog-video').length >= 1) {
  new DialogVideo();
}

if (document.getElementsByClassName('dialog-staff').length >= 1) {
  new DialogStaff();
}

if (document.getElementsByClassName('quote').length >= 1) {
  new Quote();
}

if (document.getElementsByClassName('share').length >= 1) {
  new Share();
}

if (document.getElementsByClassName('progress').length >= 1) {
  new Progress();
}

if (document.getElementsByClassName('newsletter-form').length >= 1) {
  new NewsletterForm();
}

if (document.getElementsByClassName('contact-form').length >= 1) {
  new ContactForm();
}

if (document.getElementsByClassName('page-home-hero').length >= 1) {
  new PageHomeHero();
}
